import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';	
import compact from 'lodash/compact';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import keys from 'lodash/keys';
import map from 'lodash/map';
import omitBy from 'lodash/omitBy';
import reject from 'lodash/reject';
import without from 'lodash/without';
import { useFormik } from 'formik';
import InputMask from 'react-input-mask';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import { Box, Flex, Text, BoxProps } from 'theme-ui';
import moment from 'moment';
import { DatePicker } from '../../core/DateTimePicker';
import * as actions from '../../redux/actions';
import Checkbox from '../../components/elements/Checkbox';
import TextInput from '../../components/elements/TextInput';
import Button from '../../components/elements/Button';
import { TagList } from '../../components/elements/Tag';
import ResendDexcomConnectRequestDialog from './ResendDexcomConnectRequestDialog';
import { useToasts } from '../../providers/ToastProvider';
import { getCommonFormikFieldProps } from '../../core/forms';
import { useInitialFocusedInput, useIsFirstRender, usePrevious } from '../../core/hooks';
import { dateRegex, roles, reportSchema as validationSchema } from '../../core/clinicUtils';
import { accountInfoFromClinicPatient } from '../../core/personutils';
import { Body0 } from '../../components/elements/FontStyles';
import { borders, colors } from '../../themes/baseTheme';
import Icon from '../elements/Icon';


function getFormValues(clinic,currentClinician,report = {}) {
  //console.log(clinic,report);
  return {
    time: report?.time || '',
	type: 'report',
	clinicianId: clinic?.id,
	blobId: report?.blobId,
	professionalName: currentClinician?.profile?.fullName || '',
	subType: currentClinician?.profile?.clinic?.role || '',
	deviceId: '',
	uploadId: '',
  };
}


export const AddReportForm = (props) => {
  const {
    t,
    action,
    api,
    onFormChange,
	onFileChange,
	report,
    trackMetric,
    searchDebounceMs,
    initialFocusedInput = 'professionalName',
    ...boxProps
  } = props;
  const dispatch = useDispatch();
  const isFirstRender = useIsFirstRender();
  const { set: setToast } = useToasts();
  const selectedClinicId = useSelector((state) => state.blip.selectedClinicId);
  const clinic = useSelector(state => state.blip.clinics?.[selectedClinicId]);
  const loggedInUserId = useSelector((state) => state.blip.loggedInUserId);
  const currentClinician = useSelector((state) => get(state.blip.allUsersMap, [loggedInUserId], []))
  const dateInputFormat = t('DD/MM/YYYY');
  const dateMaskFormat = dateInputFormat.replace(/[A-Z]/g, '9');
  const [initialValues, setInitialValues] = useState({});
  
   const formikContext = useFormik({
    initialValues: getFormValues(clinic,currentClinician),
    onSubmit: values => {
      const action = initial?.blobId ? 'edit' : 'create';
      //const context = selectedClinicId ? 'clinic' : 'vca';

      const actionMap = {
        edit: {
            handler: 'updatePatientReport',
            args: () => [selectedClinicId,  patient.id, omitBy({ ...initial, ...getFormValues(values) }, isEmpty)],
        },
        create: {
            handler: 'createPatientReport',
            args: () => [selectedClinicId, omitBy(values, isEmpty)],
          
        }
      }
      dispatch(actions.async[actionMap[action].handler](api, ...actionMap[action].args()));
    },
    validationSchema,
  });
    const {
    values,
    setValues,
  } = formikContext;
    
  useEffect(() => {
    // set form field values and store initial clinician values on load
    const reportValues = getFormValues(clinic,currentClinician);
	//console.log(reportValues);
    setValues(reportValues);
    setInitialValues(reportValues);
  }, [clinic,currentClinician]);
	
  useEffect(() => {
    onFormChange(formikContext);
	//console.log(values);
  }, [values]);
   

  
  function handleAsyncResult(workingState, successMessage) {
    const { inProgress, completed, notification } = workingState;

    if (!isFirstRender && !inProgress) {
      if (completed) {
        setToast({
          message: successMessage,
          variant: 'success',
        });
      }

      if (completed === false) {
        setToast({
          message: get(notification, 'message'),
          variant: 'danger',
        });
      }
    }
  } 
    var types = _.clone(roles); // eslint-disable-line new-cap
	types.unshift({
      value: '',
      label: t('Choose One')
    });
    const options = _.map(types, function(item) {
      return <option key={item.value} value={item.value}>{item.label}</option>;
    });



  return (
    <Box
      as="form"
      id="clinic-add_report-form"
    >
      <Box mb={4}>
        <TextInput
          {...getCommonFormikFieldProps('professionalName', formikContext)}
		  value={get(values, 'professionalName', '')}
	 	  onChange={(e)  => {
            formikContext.setFieldValue('professionalName', e.target.value);
			//values.professionalName = e.target.value;
			//console.log(values);
			}
		  }
          label={t('Professionnal Name')}
		  name={'professionalName'}
          placeholder={t('Professionnal Name')}
          variant="condensed"
          width="100%"
        />
      </Box>

      <Box mb={4}>
        <DatePicker 
	   value={get(values, 'time', '')} 
	   {...getCommonFormikFieldProps('time', formikContext)}
	   onChange={e=>{
		   formikContext.setFieldValue('time',e.target.value);
	   }} />
      </Box>
     <Box mb={4}>
        <label className="PatientInfo-label" htmlFor="subType">{t('Professional role')}</label>
        <select
		  {...getCommonFormikFieldProps('subType', formikContext)}
		  value={get(values, 'subType', '')}
		  onChange={(e)  => {
            formikContext.setFieldValue('subType', e.target.value);
			}
		  }
		  placeholder={t('Choose one')}>
          {options}		  
        </select>
      </Box>

      <Box mb={4}>
        <input
		  type='file'
		  accept=".pdf"
          label={t('file')}
          placeholder={t('file')}
          variant="condensed"
          width="100%"
          onChange={(e)  => {
			onFileChange(e);
			}
		  }
        />
      </Box>

    </Box>
  );
};
AddReportForm.propTypes = {
  ...BoxProps,
  api: PropTypes.object.isRequired,	
  onFileChange: PropTypes.func.isRequired,
  onFormChange: PropTypes.func.isRequired,
  report: PropTypes.object,
  trackMetric: PropTypes.func.isRequired,
  initialFocusedInput: PropTypes.string,
};

export default withTranslation()(AddReportForm); 
