import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import forEach from 'lodash/forEach';
import get from 'lodash/get'
import values from 'lodash/values'
import { Box } from 'theme-ui';
import { withTranslation } from 'react-i18next';
import { Element } from 'react-scroll';
import TodayIcon from '@material-ui/icons/Today';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ChatIcon from '@material-ui/icons/Chat';
import GroupIcon from '@material-ui/icons/Group';
import TabGroup from '../../components/elements/TabGroup';
import ClinicWorkspaceHeader from '../../components/clinic/ClinicWorkspaceHeader';
import ClinicPatients from './ClinicPatients';
import Diarry from '../diarry';
import Messages from '../messages';
import { PatientInvites } from '../share';
import * as actions from '../../redux/actions';
import config from '../../config';

export const ClinicWorkspace = (props) => {
  const { t, api, trackMetric } = props;
  const dispatch = useDispatch();
  const { tab } = useParams();
  const loggedInUserId = useSelector((state) => state.blip.loggedInUserId);
  const selectedClinicId = useSelector((state) => state.blip.selectedClinicId);
  const currentPatientInViewId = useSelector((state) => state.blip.currentPatientInViewId);
  const { fetchingPatientInvites, countingUnreadClinicianChat, fetchingUser } = useSelector((state) => state.blip.working);
  const clinic = useSelector(state => state.blip.clinics?.[selectedClinicId]);
  const chat = useSelector(state => state.blip.chat);
  const patientInvites = values(clinic?.patientInvites);
  
  const tabIndices = {
    patients: 0,
    invites: 1,
    diarry: 2,
	chat: 3,
  }

  const tabs = [
    {
      name: 'patients',
      label: t('Patient List'),
	  icon: <GroupIcon/>,
      metric: 'Clinic - View patient list',
    },
    {
      name: 'invites',
      label: t('Invites ({{count}})', { count: patientInvites.length }),
      metric: 'Clinic - View patient invites',
	  icon: <PersonAddIcon/>,
    },
	{
		name: 'diarry',
		label: t('Diarry'),
		icon: <TodayIcon/>,
		metric: 'View Diarry',
	},
	{
		name: 'chat',
		label: t('Chat ({{count}})', { count: (chat?.count ? chat?.count?.total : 0) }),
		icon: <ChatIcon/>,
		metric: 'View Chat',
	},
  ];


  const [selectedTab, setSelectedTab] = useState(get(tabIndices, tab, 0));

  useEffect(() => {
    if (tab && tabIndices[tab] !== selectedTab) {
      setSelectedTab(tabIndices[tab]);
    }
  }, [tab]);

  // Fetchers
  useEffect(() => {
	  
    if (loggedInUserId && clinic) {
      forEach([
        {
          workingState: fetchingPatientInvites,
          action: actions.async.fetchPatientInvites.bind(null, api, clinic.id),
        },
		{
		  workingState: countingUnreadClinicianChat,
          action: actions.async.countClinicChat.bind(null, api, clinic.id),
		},
      ], ({ workingState, action }) => {
        if (
          !workingState.inProgress &&
          !workingState.completed &&
          !workingState.notification
        ) {
          dispatch(action());
        }
      });
    }
  }, [loggedInUserId, clinic]);

  useEffect(() => {
    dispatch(actions.worker.dataWorkerRemoveDataRequest(null, currentPatientInViewId));
    dispatch(actions.sync.clearPatientInView());

    if (props.location?.state?.selectedClinicId && props.location?.state?.selectedClinicId !== selectedClinicId) {
      dispatch(actions.async.selectClinic(api, props.location?.state?.selectedClinicId));
    }
  }, [props.location?.state?.selectedClinicId]);

  function handleSelectTab(event, newValue) {
    trackMetric(tabs[newValue]?.metric, { clinicId: selectedClinicId, source: 'Workspace table' });
    setSelectedTab(newValue);
    dispatch(push(`/clinic-workspace/${tabs[newValue].name}`));
  }

  return (
    <>
      <ClinicWorkspaceHeader api={api} trackMetric={trackMetric} />

      <Box id="clinic-workspace" sx={{alignItems : 'center'}} variant="containers.largeBordered" mb={9}>
		<Element name="workspaceTabsTop" />								   
        <TabGroup
          aria-label="Clinic workspace tabs"
          id="clinic-workspace-tabs"
          variant="horizontal"
          tabs={tabs}
          value={selectedTab}
          onChange={handleSelectTab}
          themeProps={{
            panel: {
              p: 4,
              pb: 0,
              sx: {
                minHeight: '10em',
              },
            },
          }}
        >
          <Box id="patientsTab">
            {selectedTab === 0 && <ClinicPatients key={clinic?.id} {...props} />}
          </Box>

          <Box id="invitesTab">
            {selectedTab === 1 && <PatientInvites {...props} />}
          </Box>
			<Box id="diarryTab">
            {selectedTab === 2 && <Diarry {...props} />}
          </Box>
          <Box id="chatTab">
		   {selectedTab === 3 && <Messages {...props} />}
          </Box>
        </TabGroup>
      </Box>
    </>
  );
};

ClinicWorkspace.propTypes = {
  api: PropTypes.object.isRequired,
  trackMetric: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ClinicWorkspace);