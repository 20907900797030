import { createBrowserHistory } from 'history';
import { legacy_createStore as createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { persistState } from 'redux-devtools';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import mutationTracker from 'redux-immutable-state-invariant';
import qhistory from 'qhistory';
import assign from 'lodash/assign';
import throttle from 'lodash/throttle';
import { stringify, parse } from 'qs';

import blipState from '../reducers/initialState';
import reducers from '../reducers';
import { loadLocalState, saveLocalState } from './localStorage';

import createErrorLogger from '../utils/logErrorMiddleware';
import trackingMiddleware from '../utils/trackingMiddleware';
import createWorkerMiddleware from '../utils/workerMiddleware';
import pendoMiddleware from '../utils/pendoMiddleware';
//import launchDarklyMiddleware from '../utils/launchDarklyMiddleware';
import { keycloakMiddleware } from '../../keycloak';

function getDebugSessionKey() {
  const matches = window.location.href.match(/[?&]debug_session=([^&]+)\b/);
  return (matches && matches.length > 0)? matches[1] : null;
}

export const history = qhistory(createBrowserHistory(), stringify, parse);

const reducer = combineReducers({
  blip: reducers,
  router: connectRouter(history),
});

const loggerMiddleware = createLogger({
  level: 'info',
  collapsed: true,
});

const worker = new Worker(new URL('./../../worker/index', import.meta.url));
const workerMiddleware = createWorkerMiddleware(worker);

let enhancer;
if (!__DEV_TOOLS__) {
  enhancer = (api) => {
    const middlewares = [
      workerMiddleware,
      thunkMiddleware,
      routerMiddleware(history),
      createErrorLogger(api),
      trackingMiddleware(api),
      keycloakMiddleware(api),
    ];
    return compose(
      applyMiddleware(...middlewares),
      persistState(getDebugSessionKey()),
    );
  }
} else {
  enhancer = (api) => {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const middlewares = [
      workerMiddleware,
      thunkMiddleware,
      loggerMiddleware,
      routerMiddleware(history),
      createErrorLogger(api),
      trackingMiddleware(api),
      mutationTracker(),
      keycloakMiddleware(api),
    ];
    return composeEnhancers(
      applyMiddleware(...middlewares),
      // We can persist debug sessions this way
      persistState(getDebugSessionKey()),
    );
  }
}

function _createStore(api) {
  const initialState = { blip: assign(blipState, loadLocalState()) };
  const store = createStore(reducer, initialState, enhancer(api));

  store.subscribe(throttle(() => {
    saveLocalState({
      selectedClinicId: store.getState().blip?.selectedClinicId,
    });
  }, 1000));

  if (module.hot) {
    module.hot.accept('../reducers', () =>
      store.replaceReducer(combineReducers({
        blip: require('../reducers'),
        router: connectRouter(history),
      }))
    );
  };

  return store;
}

export default (api) => {
  return _createStore(api);
}