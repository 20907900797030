import _ from 'lodash';
import bows from 'bows';
import PropTypes from 'prop-types';
import React from 'react';
import createReactClass from 'create-react-class';
import values from 'lodash/values';
import moment from 'moment';
import { Box, Flex, Text } from 'theme-ui';
import Table from '../../components/elements/Table';
import * as actions from '../../redux/actions';
import Chat from '../chat';
import Header from './header';
import Button from '../elements/Button';



const Messages = (createReactClass({
  displayName: 'Chat',
  chartType: 'chat',
  log: bows('Chat View'),
  propTypes: {
    chartPrefs: PropTypes.object.isRequired,
    chats: PropTypes.array,
	onSendMessage : PropTypes.func.isRequired,
	onReadMessage: PropTypes.func.isRequired,
	isUserPatient : PropTypes.bool.isRequired,
	contactList: PropTypes.array,
    onClickRefresh: PropTypes.func.isRequired,
    onClickNoDataRefresh: PropTypes.func.isRequired,
    onSwitchToBasics: PropTypes.func.isRequired,
    onSwitchToDaily: PropTypes.func.isRequired,
    onSwitchToTrends: PropTypes.func.isRequired,
    onSwitchToSettings: PropTypes.func.isRequired,	
	onSwitchToChat: PropTypes.func.isRequired,
    onSwitchToAgenda: PropTypes.func.isRequired,
    onSwitchToBgLog: PropTypes.func.isRequired,
    onClickPrint: PropTypes.func.isRequired,
    patient: PropTypes.object,
	t: PropTypes.func.isRequired,
    trackMetric: PropTypes.func.isRequired,
    updateChartPrefs: PropTypes.func.isRequired,
    uploadUrl: PropTypes.string.isRequired
  },
  
  

  getInitialState: function() {
    return {
      atMostRecent: true,
      inTransition: false,
      title: ''
    };
  },

  render: function() {
	  const headerRef = React.createRef("header");
      return (
      <div id="tidelineMain">
        <Header
          chartType={this.chartType}
          patient={this.props.patient}
          atMostRecent={true}
          inTransition={this.state.inTransition}
          title={this.state.title}
		  t={this.props.t}
		  isUserPatient={this.props.isUserPatient}
          onClickMostRecent={this.handleClickMostRecent}
          onClickBasics={this.handleClickBasics}
          onClickOneDay={this.handleClickOneDay}
          onClickTrends={this.handleClickTrends}
          onClickRefresh={this.props.onClickRefresh}
		  onClickAgenda={this.handleClickAgenda}
		  onClickChat={this.handleClickChat}
          onClickSettings={this.handleClickSettings}
          onClickBgLog={this.handleClickBgLog}
          onClickPrint={this.handleClickPrint}
        ref={headerRef} />
        <div className="container-box-outer patient-data-content-outer">
          <div className="container-box-inner patient-data-content-inner">
            <div className="patient-data-content">
			{this.renderChart()}

            </div>
          </div>
        </div>
      </div>
      );
  },

  renderChart: function() {
	const selectedChatOther = this.state.selectedChatOther;
	const setSelectedChatOther = (param) => this.setState({selectedChatOther : param});
	//console.log(selectedChatOther,this.props.patient);
	const renderName = contact => (
		<Box className="chat-contact-name" onClick={() => {console.log("clicked ",contact);setSelectedChatOther(contact);}} sx={{ cursor: 'pointer' }}>
		  <Text>{contact.fullName}</Text>
		</Box>
	);
    const columns = [
		{
		  title: this.props.t('Name'),
		  field: 'name',
		  align: 'left',
		  sortable: true,
		  sortBy: 'name',
		  render: renderName,
		},
	];

    return (
     <Flex style={{flexDirection: 'row'}}>
      <Box style={{flex: 1}}>
        <Table
          id="ChatList"
          label={this.props.t('Patient List')}
          columns={columns}
          data={values(this.props.contactList)}
          orderBy="name"
          order="asc"
          emptyText={null}
          fontSize={1}
		  stickyHeader
          containerStyles={{maxHeight: '560px', overflow: 'auto'}}
        />
      </Box>
	  <Box style={{flex: 5}}>
		<Chat
			chat={_.filter(this.props.chats,function(message){
				return message.sender == selectedChatOther?.id || message.receiver == selectedChatOther?.id;
				
			})}
			t={this.props.t}
			user={this.props.patient.userid}
			other={selectedChatOther?.id}
			onClickSend={this.handleClickSend}
			readChat={this.readChat}
		/>
	  </Box>
	  </Flex>
    );
  },

 
  
  // handlers
  handleCopyAppointementsClicked: function() {
    this.props.trackMetric('Clicked Copy Settings', { source: 'Device Settings' });
  },
   handleClickBasics: function(e) {
    if (e) {
      e.preventDefault();
    }
    this.props.onSwitchToBasics();
  },
  handleClickSend : function (messageText){
	  this.props.onSendMessage(messageText,this.state.selectedChatOther.id);
  },
  readChat: function (messageId){
	  this.props.onReadMessage(messageId);
  },
  handleClickDaily: function(e) {
    if (e) {
      e.preventDefault();
    }
    this.props.onSwitchToDaily();
  },
  handleClickTrends: function(e) {
    if (e) {
      e.preventDefault();
    }
    this.props.onSwitchToTrends();
  },

  handleClickMostRecent: function(e) {
    if (e) {
      e.preventDefault();
    }
    return;
  },

  handleClickOneDay: function(e) {
    if (e) {
      e.preventDefault();
    }
    this.props.onSwitchToDaily();
  },

  handleClickAgenda: function(e) {
    if (e) {
      e.preventDefault();
    }
    this.props.onSwitchToAgenda();
  },
  handleClickChat : function(e){
	if (e) {
      e.preventDefault();
    }
	return;
	  
  },
  handleClickSettings: function(e) {
    if (e) {
      e.preventDefault();
    }

    this.props.onSwitchToSettings();
  },
  handleClickPrint: function(e) {
    if (e) {
      e.preventDefault();
    }

    this.props.onClickPrint(this.props.pdf);
  },

  handleClickBgLog: function(e) {
    if (e) {
      e.preventDefault();
    }
    this.props.onSwitchToBgLog();
  },

  toggleSettingsSection: function(deviceKey, scheduleOrProfileKey) {
    const prefs = _.cloneDeep(this.props.chartPrefs);

    if (!prefs.settings[deviceKey]) {
      prefs.settings[deviceKey] = { [scheduleOrProfileKey]: true };
    } else {
      prefs.settings[deviceKey][scheduleOrProfileKey] = !prefs.settings[deviceKey][scheduleOrProfileKey];
    }

    prefs.settings.touched = true;

    this.props.updateChartPrefs(prefs, false);
  },
}));

module.exports = Messages;
