import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';	
import { Box, BoxProps } from 'theme-ui';
import * as actions from '../../redux/actions';
import { pdfjs, Document, Page } from 'react-pdf';
import value from 'lodash/value';
import Pagination from '../../components/elements/Pagination';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};

export const Report = (props) => {
  const { t, api, report,  ...boxProps } = props;
  const dispatch = useDispatch();
  const [pdf, setPdf] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const loggedInUserId = useSelector((state) => state.blip.loggedInUserId);
  const blobs = useSelector((state) => state.blip.blobs);
   const {
    fetchingBlobContent,
  } = useSelector((state) => state.blip.working);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    // set form field values and store initial patient values on patient load
	if (
      loggedInUserId
      && !fetchingBlobContent.inProgress
    ){
		dispatch(actions.async.fetchBlobContent(api,report.blobId));
	}

  }, [report]);
  useEffect(() => {
	  if(blobs[report.blobId].blob.length > 0){
		  setPdf(blobs[report.blobId].blob);
		  console.log(pdf);
	  }
  }, [blobs]);
 const handlePageChange = (event, newValue) => {
    setPageNumber(newValue);
  };

  return (
    <Box
      id="report-show"
    >
      <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess} loading={t('Loading Report')}  noData={t('Error : no file found')} error={t('Failed to load PDF')}>
        <Page pageNumber={pageNumber} />
      </Document>
	  {numPages && numPages > 1 && (
	  <Pagination
            px="5%"
            sx={{ position: 'absolute', bottom: '-50px' }}
            width="100%"
            id="report-pagination"
            count={numPages}
            page={pageNumber}
            disabled={numPages < 2}
            onChange={handlePageChange}
            showFirstButton={false}
            showLastButton={false}
          />
	  )}
    </Box>
  );
};

Report.propTypes = {
  ...BoxProps,
  api: PropTypes.object.isRequired,
  report: PropTypes.object,
};

export default withTranslation()(Report);
