import { createElement } from 'react';

export function DatePicker({ value, onChange }: Props) {
  
  return createElement('input', {
    type: 'date',
    value: value,
    onInput: onChange,
	onChange: onChange,
  })
}
export function TimePicker({ value, onChange }: Props) {
  
  return createElement('input', {
    type: 'time',
    value: value,
    onInput: onChange,
	onChange: onChange,
  })
}