import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import omitBy from 'lodash/omitBy'
import { useFormik } from 'formik';
import InputMask from 'react-input-mask';
import { Box, BoxProps } from 'theme-ui';
import { DatePicker } from '../core/DateTimePicker';
import * as actions from '../redux/actions';
import TextInput from './elements/TextInput';
import { getCommonFormikFieldProps } from '../core/forms';
import { constantsSchema as validationSchema } from '../core/clinicUtils';
import { accountInfoFromClinicPatient } from '../core/personutils';
import { Body1 } from './elements/FontStyles';
import { withTranslation } from 'react-i18next';

export const ConstantsForm = (props) => {
  const {t, api, onFormChange, constants, ...boxProps } = props;
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState({});
  const [constantsValues, setConstantsValues] = useState({});
  const dateInputFormat = t('DD/MM/YYYY');
  const dateMaskFormat = dateInputFormat.replace(/[A-Z]/g, '9');
  const formikContext = useFormik({
    initialValues: getFormValues(constants),
    onSubmit: values => {
      const action = 'create';
      //const context = selectedClinicId ? 'clinic' : 'vca';

      const actionMap = {
        create: {
            handler: 'addConstants',
            args: () => [constants, omitBy(values, isEmpty)],
          
        }
      }
      dispatch(actions.async[actionMap[action].handler](api, ...actionMap[action].args()));
    },
    validationSchema,
  });

  const {
    values,
    setValues,
  } = formikContext;

  function getFormValues(source) {
    return {
	  weight: get(source, 'weight', ''),
      height: get(source, 'height', ''),
	  time: get(source, 'time','')
    };
  }
     
   const handleChange = (e) => {
	   formikContext.setFieldValue('time', e.target.value);
	   values.date = e.target.value;
	   console.log(e.target.value, values);
  };

  useEffect(() => {
    // set form field values and store initial patient values on patient load
    const constantsValues = getFormValues(constants);
    setValues(constantsValues);
    setInitialValues(constantsValues);
  }, [constants]);

  useEffect(() => {
    onFormChange(formikContext);
  }, [values]);

  return (
    <Box
      as="form"
      id="clinic-appointement-form"
    >

      <Box mb={4}	  >
	    <Body1>
        {t('Constants Date')}
       </Body1>
      <DatePicker 
	   value={get(values, 'time', '')} 
	   {...getCommonFormikFieldProps('time', formikContext)}
	   onChange={ (e) =>{
		   formikContext.setFieldValue('time', e.target.value);
		   //setSelectedAppointementDate(e.target.value);
		   values.time = e.target.value;
		   console.log(e.target.value, values);
		   //this.setState({value : e.target.value});
	   }} />
	   <TextInput
			name="weight"
			label={t('Weight')}
			variant="condensed"
			width="100%"
			{...getCommonFormikFieldProps('weight', formikContext)}
			value={get(values, 'weight', '0')}
			onChange={e => {
			formikContext.setFieldValue('weight', e.target.value);
			}}
			onBlur={e => {
			formikContext.setFieldTouched('weight');
			formikContext.setFieldValue('weight', e.target.value);
			}}
		/> kg
	   <TextInput
			name="height"
			label={t('Height')}
			{...getCommonFormikFieldProps('height', formikContext)}
			variant="condensed"
			width="100%"
			value={get(values, 'height', '0')}
			onChange={e => {
				formikContext.setFieldValue('height', e.target.value);
			}}
			onBlur={e => {
			formikContext.setFieldTouched('height');
			formikContext.setFieldValue('height', e.target.value);
			}}
		  /> cm
      </Box>
    </Box>
  );
};

ConstantsForm.propTypes = {
  ...BoxProps,
  api: PropTypes.object.isRequired,
  onFormChange: PropTypes.func.isRequired,
  constants: PropTypes.object,
};

export default withTranslation()(ConstantsForm);
