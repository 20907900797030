import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { withTranslation } from 'react-i18next';	
import { push } from 'connected-react-router';
import get from 'lodash/get';
import omit from 'lodash/omit';
import { Box, Flex, Text, Link, BoxProps } from 'theme-ui';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import GroupRoundedIcon from '@material-ui/icons/GroupRounded';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { components as vizComponents } from '@diabee/viz';
import { Caption } from '../elements/FontStyles';

import Button from '../elements/Button';
import Icon from '../elements/Icon';
import Pill from '../elements/Pill';


const { ClipboardButton } = vizComponents;

export const ClinicWorkspaceHeader = (props) => {
  const { t, api, trackMetric, ...boxProps } = props;
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const clinics = useSelector((state) => state.blip.clinics);
  const selectedClinicId = useSelector((state) => state.blip.selectedClinicId);
  const clinic = get(clinics, selectedClinicId);
  const isWorkspacePath = pathname.indexOf('/clinic-workspace') === 0;

  const buttonText = useMemo(() =>
    <Icon
      variant="static"
      icon={FileCopyRoundedIcon}
      label={t('Copy Share Code')}
      title={t('Copy Share Code')}
    />, [t]
  );

  const buttonSuccessText = useMemo(() => <span className="success">{t('✓')}</span>, [t]);

  const navigationAction = {
    label: isWorkspacePath ? t('Workspace Settings'): t('View Patient List'),
    icon: isWorkspacePath ? SettingsRoundedIcon : GroupRoundedIcon,
    action: () => dispatch(push(isWorkspacePath ? '/clinic-admin' : '/clinic-workspace')),
    metric: isWorkspacePath ? 'Clinic - View clinic members' : 'Clinic - View patient list',
  };

  const getButtonText = useCallback(() => clinic?.shareCode, [clinic?.shareCode]);

  const buttonOnClick = useCallback(() => {
    trackMetric('Clinic - Copy clinic share code', {
      clinicId: selectedClinicId,
    });
  }, [selectedClinicId]);

  function handleNavigationAction() {
    const source = isWorkspacePath ? undefined : 'Clinic members';
    trackMetric(navigationAction.metric, { clinicId: selectedClinicId, source });
    navigationAction.action();
  }

  if (!clinic) return null;

  return (
    <Box
      variant="containers.largeBordered"
      mb={4}
	  {...omit(boxProps,['tReady'])}
    >
      <Flex
        id="clinicProfileDetails"
        px={4}
        py={3}
        sx={{
          columnGap: 5,
          rowGap: 2,
		  flexWrap: 'wrap',
		  justifyContent: ['center', 'space-between'],
		  alignItems: 'center'
        }}
      >
        <Flex
          sx={{
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
            width:['100%', '100%', 'auto'],
            flexWrap: 'wrap',
            columnGap: 5,
            rowGap: 2,
          }}
        >
          <Box sx={{ flexShrink: 0 }}>
            <Text as="span" sx={{ color: 'text.primary', fontSize: [1, 2, '18px'], fontWeight: 'medium' }}>{clinic.name}</Text>
          </Box>
          <Flex sx={{ color: 'text.primary', flexShrink: 0, gap: 2, fontSize: 0, alignItems: 'flex-end' }}>
            <Caption>{t('Share Code:')}</Caption>
            <Flex
              sx={{
                columnGap: 2,
                alignItems: 'flex-start',
                button: {
                  border: 'none',
                  color: 'text.primary',
                  top: '1px',
                  p: 0,
                  m: 0,
                  position: 'relative',
                  '&:hover,&:active': {
                    border: 'none',
                    color: 'text.primary',
                    backgroundColor: 'transparent',
                  },
                },
                '.success': {
                  position: 'relative',
                  display: 'block',
                  top: '2px',
                },
              }}
            >
              <Text as="span" sx={{ whiteSpace: 'nowrap', fontWeight: 'medium' }}>{clinic.shareCode}</Text>
              <ClipboardButton
                buttonTitle={t('Copy Share Code')}
                buttonText={buttonText}
                successText={buttonSuccessText}
                onClick={buttonOnClick}
                getText={getButtonText}
              />
            </Flex>
          </Flex>
        </Flex>

        <Flex          
          sx={{ gap: 3,
				alignItems: 'center',
				width : ['100%', '100%', 'auto'],
				justifyContent : ['flex-start', 'flex-start', 'flex-end']
		  }}
        >
          <Box>
            <Button
              id="profileNavigationButton"
              variant="textSecondary"
              onClick={handleNavigationAction}
              icon={navigationAction.icon}
              iconPosition='left'
              iconFontSize="1.25em"
              iconLabel={navigationAction.label}
              sx={{fontSize : 0}}
              pl={0}
            >
              {navigationAction.label}
            </Button>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

ClinicWorkspaceHeader.propTypes = {
  ...BoxProps,
  api: PropTypes.object.isRequired,
  trackMetric: PropTypes.func.isRequired,
};

export default withTranslation()(ClinicWorkspaceHeader);