import _ from 'lodash';
import bows from 'bows';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import values from 'lodash/values';
import moment from 'moment';
import { Box, Flex, Text } from 'theme-ui';
import Table from '../../components/elements/Table';
import * as actions from '../../redux/actions';
import { withTranslation} from 'react-i18next';


const Constants = withTranslation()(class Constants extends Component{


  static propTypes = {
    data: PropTypes.object.isRequired,
  };


  render = () => {
      return (
      <div id="tidelineMain">
        <div className="container-box-outer patient-data-content-outer">
          <div className="container-box-inner patient-data-content-inner">
            <div className="patient-data-content">
              {this.noConstants() ? this.renderNoConstants() : this.renderChart()}
            </div>
          </div>
        </div>
      </div>
      );
  };

  renderChart = () => {
	const renderWeight = constant => {
		return(
		<Box className="appointement-professional-name">
		  <Text>{constant.weight}{" kg"}</Text>
		</Box>
	)};
	const renderHeight = constant => (
		<Box className="appointement-professional-name">
		  <Text>{constant.height}{" cm"}</Text>
		</Box>
	);
	const renderDate = constant => (
		<Box className="constants-date">
		  <Text>{moment(constant.time).format("L")}</Text>
		</Box>
	);
	const renderBMI = constant => (	
		<Box className="BMI">
		  <Text>{(constant.weight /((constant.height/100)*(constant.height/100))).toPrecision(3)}</Text>
		</Box>
	);
    const columns = [
		{
		  title: t('Date'),
		  field: 'time',
		  align: 'left',
		  sortable: true,
		  sortBy: 'time',
		  render: renderDate,
		},
		{
		  title: t('Weight'),
		  field: 'weight',
		  align: 'left',
		  sortable: true,
		  sortBy: 'weight',
		  render: renderWeight,
		},
		{
		  title: t('Height'),
		  field: 'height',
		  align: 'left',
		  sortable: true,
		  sortBy: 'height',
		  render: renderHeight,
		},
		{
		  title: t('GMI'),
		  field: 'gmi',
		  align: 'left',
		  sortable: true,
		  sortBy: 'time',
		  render: renderBMI,
		},
	];
	var datum = [{time:this.props.data.metaData.latestDatumByType.constants.time,
				weight: this.props.data.metaData.latestDatumByType.constants.weight,
				height: this.props.data.metaData.latestDatumByType.constants.height,
		  }];
    return (
      <Box sx={{ position: 'relative' }}>
        <Table
          id="ConstantTable"
          label={this.props.t('Latests Constants')}
          columns={columns}
          data={datum}
          orderBy="time"
          order="asc"
          emptyText={null}
          fontSize={1}
        />
      </Box>
    );
  };

  renderNoConstants = () => {
    return ;

  };

  noConstants = () => {
    if(this.props.data.metaData.latestDatumByType.constants){
		return false;
	}
	return true;
  };
 
});

module.exports = Constants;
