import _ from 'lodash';
import bows from 'bows';
import PropTypes from 'prop-types';
import React from 'react';
import createReactClass from 'create-react-class';
import { withTranslation , Trans } from 'react-i18next';
import values from 'lodash/values';
import moment from 'moment';
import { Box, Flex, Text } from 'theme-ui';
import Table from '../../components/elements/Table';
import * as actions from '../../redux/actions';
import Header from './header';
import Button from '../elements/Button';
import { roles } from '../../core/clinicUtils';

const Agenda = withTranslation()(createReactClass({
  displayName: 'Agenda',
  chartType: 'agenda',
  log: bows('Settings View'),

  propTypes: {
    chartPrefs: PropTypes.object.isRequired,
    appointements: PropTypes.array,
    onClickRefresh: PropTypes.func.isRequired,
    onClickNoDataRefresh: PropTypes.func.isRequired,
    onSwitchToBasics: PropTypes.func.isRequired,
    onSwitchToDaily: PropTypes.func.isRequired,
    onSwitchToTrends: PropTypes.func.isRequired,
    onSwitchToSettings: PropTypes.func.isRequired,	
	onSwitchToChat: PropTypes.func.isRequired,
    onSwitchToAgenda: PropTypes.func.isRequired,
	onSwitchToReports: PropTypes.func.isRequired,
    onSwitchToBgLog: PropTypes.func.isRequired,
    onClickPrint: PropTypes.func.isRequired,
    patient: PropTypes.object,
    trackMetric: PropTypes.func.isRequired,
    updateChartPrefs: PropTypes.func.isRequired,
	//t: PropTypes.func.isRequired,
	i18n: PropTypes.object.isRequired,
    uploadUrl: PropTypes.string.isRequired
  },

  getInitialState: function() {
    return {
      atMostRecent: true,
      inTransition: false,
      title: ''
    };
  },

  render: function() {
      return (
      <div id="tidelineMain">
        <Header
          chartType={this.chartType}
          patient={this.props.patient}
          atMostRecent={true}
          inTransition={this.state.inTransition}
          title={this.state.title}
		  i18n={this.props.i18n}
          onClickMostRecent={this.handleClickMostRecent}
		  isUserPatient={this.props.isUserPatient}
          onClickBasics={this.handleClickToBasics}
          onClickOneDay={this.handleClickOneDay}
          onClickTrends={this.handleClickTrends}
          onClickRefresh={this.props.onClickRefresh}
		  onClickAgenda={this.handleClickAgenda}
		  onClickChat={this.handleClickChat}
          onClickSettings={this.handleClickSettings}
          onClickBgLog={this.handleClickBgLog}
          onClickPrint={this.handleClickPrint}
		  onClickReports={this.handleClickReports}
        ref="header" />
        <div className="container-box-outer patient-data-content-outer">
          <div className="container-box-inner patient-data-content-inner">
            <div className="patient-data-content">
              {this.noAppointements() ? this.renderNoAppointementsMessage() : this.renderChart()}

            </div>
          </div>
        </div>
      </div>
      );
  },

  renderChart: function() {	
    const specialityLabels = _.mapValues(_.keyBy(roles,'values'),'label');
	const renderName = ({professionalName}) => (
		<Box className="appointement-professional-name">
		  <Text>{professionalName}</Text>
		</Box>
	);
	const renderLocal = ({localisation}) => (
		<Box className="appointement-professional-name">
		  <Text>{localisation}</Text>
		</Box>
	);
	const renderDate = ({ time }) => (
		<Box className="appointement-date">
		  <Text>{moment(time).format(this.props.t('MM/DD/YYYY h:mm a'))}</Text>
		</Box>
	);
	const renderSpeciality = ({ speciality }) => (	
		<Box className="appointement-speciality">
		  <Text>{specialityLabels[speciality]}</Text>
		</Box>
	);
    const columns = [
		{
		  title: this.props.t('Professional Name'),
		  field: 'name',
		  align: 'left',
		  sortable: true,
		  sortBy: 'name',
		  render: renderName,
		},
		{
		  title: this.props.t('localisation'),
		  field: 'localisation',
		  align: 'left',
		  sortable: true,
		  sortBy: 'name',
		  render: renderLocal,
		},
		{
		  title: this.props.t('Appointement Date'),
		  field: 'time',
		  align: 'left',
		  sortable: true,
		  sortBy: 'time',
		  render: renderDate,
		},
		{
		  title: this.props.t('Professional Speciality'),
		  field: 'speciality',
		  render: renderSpeciality,
		  align: 'left',
		  sortable: true,
		  sortBy: 'time',
		},
	];

    return (
      <Box sx={{ position: 'relative' }}>
        <Table
          id="appointementsTable"
          label={this.props.t('Appointements Table')}
          columns={columns}
          data={values(this.props.appointements)}
          orderBy="time"
          order="asc"
          emptyText={null}
          fontSize={1}
        />
      </Box>
    );
  },

  renderNoAppointementsMessage: function() {
    const self = this;	
    const handleClickUpload = function() {
      self.props.trackMetric('Clicked Partial Data Upload, No Settings');
    };

    return (
      <Trans className="patient-data-message patient-data-message-loading" i18nKey="html.no-appointements" >
        <p>You seems to not have pendings appointements.</p>
      </Trans>
    );

  },

  noAppointements: function() {
    if(this.props.appointements){
		if(this.props.appointements.length === 0){
			return true;
		}
		return false;
	}
	return true;
  },
  
  // handlers
  handleCopyAppointementsClicked: function() {
    this.props.trackMetric('Clicked Copy Settings', { source: 'Device Settings' });
  },
   handleClickBasics: function(e) {
    if (e) {
      e.preventDefault();
    }
    this.props.onSwitchToBasics();
  },
  handleClickDaily: function(e) {
    if (e) {
      e.preventDefault();
    }
    this.props.onSwitchToDaily();
  },
  handleClickTrends: function(e) {
    if (e) {
      e.preventDefault();
    }
    this.props.onSwitchToTrends();
  },

  handleClickMostRecent: function(e) {
    if (e) {
      e.preventDefault();
    }
    return;
  },

  handleClickOneDay: function(e) {
    if (e) {
      e.preventDefault();
    }
    this.props.onSwitchToDaily();
  },

  handleClickAgenda: function(e) {
    if (e) {
      e.preventDefault();
    }
    return;
  },
  handleClickChat : function(e){
	if (e) {
      e.preventDefault();
    }
	this.props.onClickChat();
	  
  },
  handleClickReports : function(e){
	if (e) {
      e.preventDefault();
    }
	this.props.onClickReports();
  },
  handleClickSettings: function(e) {
    if (e) {
      e.preventDefault();
    }

    this.props.onSwitchToSettings();
  },
  handleClickPrint: function(e) {
    if (e) {
      e.preventDefault();
    }

    this.props.onClickPrint(this.props.pdf);
  },

  handleClickBgLog: function(e) {
    if (e) {
      e.preventDefault();
    }
    this.props.onSwitchToBgLog();
  },

  toggleSettingsSection: function(deviceKey, scheduleOrProfileKey) {
    const prefs = _.cloneDeep(this.props.chartPrefs);

    if (!prefs.settings[deviceKey]) {
      prefs.settings[deviceKey] = { [scheduleOrProfileKey]: true };
    } else {
      prefs.settings[deviceKey][scheduleOrProfileKey] = !prefs.settings[deviceKey][scheduleOrProfileKey];
    }

    prefs.settings.touched = true;

    this.props.updateChartPrefs(prefs, false);
  },
}));

module.exports = Agenda;
