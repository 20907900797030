import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';	
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import omitBy from 'lodash/omitBy'
import { useFormik } from 'formik';
import InputMask from 'react-input-mask';
import { Box, BoxProps } from 'theme-ui';
import { DatePicker, TimePicker } from '../../core/DateTimePicker';
import * as actions from '../../redux/actions';
import TextInput from '../../components/elements/TextInput';
import { getCommonFormikFieldProps } from '../../core/forms';
import { appointementSchema as validationSchema } from '../../core/clinicUtils';
import { accountInfoFromClinicPatient } from '../../core/personutils';
import { Body1 } from '../../components/elements/FontStyles';

export const AppointementForm = (props) => {
  const { t, api, patients, onFormChange, appointement, ...boxProps } = props;
  const dispatch = useDispatch();
  const selectedClinicId = useSelector((state) => state.blip.selectedClinicId);
  const [initialValues, setInitialValues] = useState({});
  const [selectedAppointementDate, setSelectedAppointementDate] = useState({});
  
  const formikContext = useFormik({
    initialValues: getFormValues(appointement),
    onSubmit: values => {
      const action = appointement?.id ? 'edit' : 'create';
      //const context = selectedClinicId ? 'clinic' : 'vca';

      const actionMap = {
        edit: {
            handler: 'updateAppointement',
            args: () => [selectedClinicId, appointement.id, omitBy({ ...appointement, ...getFormValues(values) }, isEmpty)],
        },
        create: {
            handler: 'createAppointement',
            args: () => [selectedClinicId, omitBy(values, isEmpty)],
          
        }
      }
      dispatch(actions.async[actionMap[action].handler](api, ...actionMap[action].args()));
    },
    validationSchema,
  });

  const {
    values,
    setValues,
  } = formikContext;

  function getFormValues(source) {
    return {
      userId: get(source, 'userId', ''),
	  name: get(source, 'name', ''),
      date: (get(source, 'time', '')).substring(0,10), //yyyy-MM-ddTHH:mm:ssZ
	  hour: (get(source, 'time', '')).substring(11,16),
    };
  }
  
  const renderPatientsList = () => {
  var patientList = {...patients};
	patientList.nil = {fullName :'', id : '-1'};
    var options = _.map(patientList, function(item) {
		//console.log(item);
      return <option key={item.id} value={item.fullName} id={item.id}>{item.fullName}</option>;
    });
	//console.log(options);
    return (
      <select
        className="PatientsList-control PatientsList-control--list"
        name="patient"
        value={values.name}
		{...getCommonFormikFieldProps('name', formikContext)}
        onChange={e => {
			//console.log(e.target.options.selectedIndex);
			const index = e.target.options.selectedIndex
			//console.log(e.target.options[index], e.target.options[index].id, e.target.options[index].value);
			formikContext.setFieldValue('userId',e.target.options[index].id);
			formikContext.setFieldValue('name',e.target.options[index].value);
			//values.userId =  e.target.key;
			//appointement.userId = e.target.value;
		}}>
        {_.sortBy(options,[function(o) {
			//console.log(o.props.value);
			return o.props.value;
			}])}
      </select>
    );
  };
  
   const handleChange = (e) => {
	   console.log(e);
    /*var target = e.target;
    var value = this.state.value;
    value[target.name] = target.value;

    if (this.props.onChange) {
      this.props.onChange({
        name: this.props.name,
        value: value
      });
    }*/
  };

  useEffect(() => {
    // set form field values and store initial patient values on patient load
    const appointementValues = getFormValues(appointement);
    setValues(appointementValues);
    setInitialValues(appointementValues);
  }, [appointement]);

  useEffect(() => {
    onFormChange(formikContext);
  }, [values]);

  return (
    <Box
      as="form"
      id="clinic-appointement-form"
    >
      <Box mb={4}>
		  {!appointement?.id && renderPatientsList()}
      </Box>

      <Box mb={4}	  >
	    <Body1>
        {t('Appointement Date')}
       </Body1>
       <DatePicker 
	   value={get(values, 'date', '')} 
	   {...getCommonFormikFieldProps('date', formikContext)}
	   onChange={e=>{
		   formikContext.setFieldValue('date', e.target.value);
		   //setSelectedAppointementDate(e.target.value);
		   values.date = e.target.value;
		   console.log(e.target.value, values);
		   //this.setState({value : e.target.value});
	   }} />
	   <TimePicker 
	   value={get(values, 'hour', '')}
	   {...getCommonFormikFieldProps('hour', formikContext)}
	   onChange={ e=> {
		   ///setSelectedAppointementDate(new Date(selectedAppointementDate).setTime(e.target.value));
		   formikContext.setFieldValue('hour', e.target.value);		   
		   values.hour =  e.target.value;
		   console.log(e.target.value, values);
		   //this.setState({value : e.target.value});
	   }} />
      </Box>
    </Box>
  );
};

AppointementForm.propTypes = {
  ...BoxProps,
  api: PropTypes.object.isRequired,
  onFormChange: PropTypes.func.isRequired,
  patients : PropTypes.array.isRequired,
  appointement: PropTypes.object,
};

export default withTranslation()(AppointementForm);
