import _ from 'lodash';
import bows from 'bows';
import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import createReactClass from 'create-react-class';
import values from 'lodash/values';
import moment from 'moment';
import { Box, Flex, Text } from 'theme-ui';
import Table from '../elements/Table';
import * as actions from '../../redux/actions';
import Report from '../clinic/Report';
import { roles } from '../../core/clinicUtils';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '../elements/Dialog';

import {
  MediumTitle,
  Body1,
  Paragraph0,
} from '../elements/FontStyles';

import Header from './header';
import Button from '../elements/Button';


const Reports = (createReactClass({
  displayName: 'Reports',
  chartType: 'Report',
  log: bows('Reports View'),

  propTypes: {
	api: PropTypes.object.isRequired,
    chartPrefs: PropTypes.object.isRequired,
    reports: PropTypes.array,
    onClickRefresh: PropTypes.func.isRequired,
    onClickNoDataRefresh: PropTypes.func.isRequired,
    onSwitchToBasics: PropTypes.func.isRequired,
    onSwitchToDaily: PropTypes.func.isRequired,
    onSwitchToTrends: PropTypes.func.isRequired,
    onSwitchToSettings: PropTypes.func.isRequired,	
	onSwitchToChat: PropTypes.func.isRequired,
    onSwitchToAgenda: PropTypes.func.isRequired,
    onSwitchToBgLog: PropTypes.func.isRequired,
	onSwitchToReports: PropTypes.func.isRequired,
    onClickPrint: PropTypes.func.isRequired,
    patient: PropTypes.object,
    trackMetric: PropTypes.func.isRequired,
    updateChartPrefs: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired,
	i18n: PropTypes.object.isRequired,
    uploadUrl: PropTypes.string.isRequired
  },
  

  
  
  getInitialState: function() {
    return {
      atMostRecent: true,
	  file: null,
	  showUploadReport: false,
      inTransition: false,
      title: '',
	  selectedReport: null,
	  showPdfReport: false,
    };
  },
  
  handleClickReport: function(report) {
	  return () => {
		console.log(report);
		if(report){
			this.setState({selectedReport: report});
			this.setState({showPdfReport : true });
		}
		console.log(this.state.showPdfReport)
    }
  },
  handleCloseOverlays: function() {
	  this.setState({selectedReport: null});
	  this.setState({showPdfReport : false });
  },
  renderReportPdf : function() {
	  return (
      <Dialog
        id="reportPdf"
        aria-labelledby="dialog-title"
        open={this.state.showPdfReport}
        onClose={this.handleCloseOverlays}
		maxWidth="xl"
      >
        <DialogTitle onClose={this.handleCloseOverlays}>
          <MediumTitle id="dialog-title">{this.props.t('Report')}</MediumTitle>
        </DialogTitle>

        <DialogContent>
          <Report api={this.props.api} report={this.state.selectedReport} t={this.props.t} />
        </DialogContent>

      </Dialog>
    );
  },
  
  render: function() {
      return (
      <div id="tidelineMain">
        <Header
          chartType={this.chartType}
          patient={this.props.patient}
          atMostRecent={true}
          inTransition={this.state.inTransition}
          title={this.state.title}
		  t={this.props.t}
          onClickMostRecent={this.handleClickMostRecent}
		  isUserPatient={this.props.isUserPatient}
          onClickBasics={this.handleClickBasics}
          onClickOneDay={this.handleClickOneDay}
          onClickTrends={this.handleClickTrends}
          onClickRefresh={this.props.onClickRefresh}
		  onClickAgenda={this.handleClickAgenda}
		  onClickChat={this.handleClickChat}
          onClickSettings={this.handleClickSettings}
          onClickBgLog={this.handleClickBgLog}
          onClickPrint={this.handleClickPrint}
		  onClickReports={this.handleClickReports}
        ref="header" />
        <div className="container-box-outer patient-data-content-outer">
          <div className="container-box-inner patient-data-content-inner">
            <div className="patient-data-content">
              {this.noReports() ? this.renderNoReportsMessage() : this.renderChart()}
			  {this.state.showPdfReport && this.renderReportPdf()}
            </div>
          </div>
        </div>
      </div>
      );
  },

  renderChart: function() {
    const { t } = this.props;
    const specialityLabels = _.mapValues(_.keyBy(roles,'values'),'label');
	const renderDate = (report) => (
		<Box className="report-time" onClick={this.handleClickReport(report)}>
		  <Text>{moment(report.time).format("L")}</Text>
		</Box>
	);
	const renderName = report => (
		<Box className="report-professional-name" onClick={this.handleClickReport(report)}>
		  <Text>{report.professionalName}</Text>
		</Box>
	);
	const renderSpeciality = report => (	
		<Box className="Report-speciality" onClick={this.handleClickReport(report)}>
		  <Text>{specialityLabels[report?.subType]}</Text>
		</Box>
	);
    const columns = [
		{
		  title: this.props.t('Date'),
		  field: 'time',
		  align: 'left',
		  sortable: true,
		  sortBy: 'time',
		  render: renderDate,
		},		
		{
		  title: this.props.t('Professional Name'),
		  field: 'name',
		  align: 'left',
		  sortable: true,
		  sortBy: 'name',
		  render: renderName,
		},		
		{
		  title: this.props.t('Professional Speciality'),
		  field: 'speciality',
		  render: renderSpeciality,
		  align: 'left',
		  sortable: true,
		  sortBy: 'speciality',
		},
	];

    return (
      <Box sx={{ position: 'relative' }}>
        <Table
          id="reportsTable"
          label={this.props.t('Reports Table')}
          columns={columns}
          data={values(this.props.reports)}
          orderBy="time"
          order="desc"
          emptyText={null}
          fontSize={1}
        />
      </Box>
    );
  },

  renderNoReportsMessage: function() {
    const self = this;	
    const { t, i18n } = this.props;
    const handleClickUpload = function() {
      self.props.trackMetric('Clicked Partial Data Upload, No Settings');
    };

    return (
      <Trans className="patient-data-message patient-data-message-loading" i18nKey="html.no-reports" t={t} i18n={i18n}>
        <p>It's seems this patient do not have any reports </p>
      </Trans>
    );

  },

  noReports: function() {
    if(this.props.reports){
		if(this.props.reports.length === 0){
			return true;
		}
		return false;
	}
	return true;
  },
  
  // handlers
  handleCopyReportsClicked: function() {
    this.props.trackMetric('Clicked Copy Settings', { source: 'Device Settings' });
  },
   handleClickBasics: function(e) {
    if (e) {
      e.preventDefault();
    }
    this.props.onSwitchToBasics();
  },
  handleClickDaily: function(e) {
    if (e) {
      e.preventDefault();
    }
    this.props.onSwitchToDaily();
  },
  handleClickTrends: function(e) {
    if (e) {
      e.preventDefault();
    }
    this.props.onSwitchToTrends();
  },

  handleClickMostRecent: function(e) {
    if (e) {
      e.preventDefault();
    }
    return;
  },

  handleClickOneDay: function(e) {
    if (e) {
      e.preventDefault();
    }
    this.props.onSwitchToDaily();
  },

  handleClickAgenda: function(e) {
    if (e) {
      e.preventDefault();
    }
    this.props.onSwitchToAgenda;
  },
  handleClickReports: function(e) {
    if (e) {
      e.preventDefault();
    }
    return;
  },
  handleClickChat : function(e){
	if (e) {
      e.preventDefault();
    }
	this.props.onClickChat();
	  
  },
  handleClickSettings: function(e) {
    if (e) {
      e.preventDefault();
    }

    this.props.onSwitchToSettings();
  },
  handleClickPrint: function(e) {
    if (e) {
      e.preventDefault();
    }

    this.props.onClickPrint(this.props.pdf);
  },

  handleClickBgLog: function(e) {
    if (e) {
      e.preventDefault();
    }
    this.props.onSwitchToBgLog();
  },

  toggleSettingsSection: function(deviceKey, scheduleOrProfileKey) {
    const prefs = _.cloneDeep(this.props.chartPrefs);

    if (!prefs.settings[deviceKey]) {
      prefs.settings[deviceKey] = { [scheduleOrProfileKey]: true };
    } else {
      prefs.settings[deviceKey][scheduleOrProfileKey] = !prefs.settings[deviceKey][scheduleOrProfileKey];
    }

    prefs.settings.touched = true;

    this.props.updateChartPrefs(prefs, false);
  },
}));

module.exports = Reports;
