import _ from 'lodash';
import bows from 'bows';
import PropTypes from 'prop-types';
import React from 'react';
import createReactClass from 'create-react-class';
import { Trans, withTranslation } from 'react-i18next';
import values from 'lodash/values';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { Box, Flex, Text } from 'theme-ui';
import Table from '../elements/Table';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import SendIcon from '@material-ui/icons/Send';
import * as actions from '../../redux/actions';
import TextInput from '../elements/TextInput';
import Button from '../elements/Button';



export const Chat = (props) => {
  const { t, api, trackMetric, searchDebounceMs, chat, user, other, onClickSend, loggedInUser,readChat } = props;

  const [messageText, setMessageText] = React.useState('');
  
 function handleMessageChange(event) {
    setMessageText(event.target.value);
  }
 function handleClickSend(){
	 onClickSend(messageText);
	 setMessageText('');
 }
  function handleClickChatPatient(patient){
	  setSelectedChatPatient(patient);
  }
  const renderText = message => {
	 //console.log(message);
	if(message.sender == user) {
		console.log(message,message.original_sender, props.clinic,props.user,message.original_sender == props.user);
		return (
		<Flex style={{flexDirection: 'column'}}>
		<Box style={{
                    backgroundColor: "#0078fe",
                    padding:10,
                    marginLeft: '45%',
                    borderRadius: 5,
                   
                    marginTop: 5,
                    marginRight: "5%",
                    maxWidth: '50%',
                    alignSelf: 'flex-end',
                    borderRadius: 20,
                  }}>
			<Text style={{color: 'white',fontWeight: 'bold', fontSize: 8,margin:'5px'}}>
					{message.loggedInUser ? (message.original_sender == loggedInUser ? t("You") : message.senderName + " - " + message.originalSenderName) : t("You") }
			</Text>
			<Text style={{color: 'white',margin:'5px'}}>
				{message.messagetext}
			</Text>
			<Text style={{color: 'white', fontSize: 8,margin:'5px'}}>
				{moment(message.timestamp).format(t("MM/DD/YYYY h:mm a"))}{message.readed ? t('readed'):""}
			</Text>
		</Box>
		</Flex>);
	}
	else if(message.receiver == user){
		if(!message.readed)
			readChat(message.id);
		return (
			<Flex style={{flexDirection: 'column'}}>
				<Box style={{
                    backgroundColor: "#dedede",
                    padding:10,
                    borderRadius: 5,
                    marginTop: 5,
                    marginLeft: "5%",
                    maxWidth: '50%',
                    alignSelf: 'flex-start',
                    //maxWidth: 500,
                    //padding: 14,
                    
                    //alignItems:"center",
                    borderRadius: 20,
                  }}>
					<Text style={{fontWeight: 'bold', fontSize: 8,margin:'5px'}}>
						{message.senderName + (message.originalSenderName ? (message.originalSenderName == message.senderName ? "" :  " - " + message.originalSenderName) : "")}
					</Text>
					<Text style={{margin:'5px'}}>
						{message.messagetext}
					</Text>
					<Text style={{fontSize: 8,margin:'5px'}}>
						{moment(message.timestamp).format(t("MM/DD/YYYY h:mm a"))}{message.readed ? t('readed'):""}
					</Text>
				</Box>
			</Flex>
		);
	}	
		
   };
   const renderSendChat = () =>{
		  return(
			<TextInput
				themeProps={{
				  width: 'auto',
				  minWidth: '250px',
				}}
				fontSize="12px"
				id="chat-text"
				placeholder={t('Enter chat text here')}
				icon={SendIcon}
				iconLabel={t('Send')}
				onClickIcon={!isEmpty(messageText) ? handleClickSend : null}
				name="chat-text"
				onChange={handleMessageChange}
				value={messageText}
				variant="condensed"
			  />
	  
	  
	  );
  }; 
  const renderChat = () =>{
	//console.log(chat,user,other);
	const columns = [
		{
		  title: t('Chat'),
		  field: 'messageText',
		  render: renderText,
		},
	];

    return (
      <Box sx={{ position: 'relative' }}>
        <Table
          id="ContactTable"
          label={t('Chat')}
          columns={columns}
          data={values(chat)}
          orderBy="timestamp"
          order="asc"
          emptyText={null}
          fontSize={1}
		  stickyHeader
		  containerStyles={{maxHeight: '560px', overflow: 'auto'}}
        />
      </Box>
    );
  };
  return (

		<Flex style={{flexDirection: 'column'}}>
			<Box style={{flex: 5}}>
				{renderChat()}
			</Box>
			<Box style={{flex: 1}}>
				{renderSendChat()}
			</Box>
		</Flex>


    );
};
Chat.propTypes =  {
    chat: PropTypes.array.isRequired,
	user: PropTypes.string.isRequired,
	userId : PropTypes.string,
    other: PropTypes.string,	
	onClickSend : PropTypes.func.isRequired,
	readChat: PropTypes.func.isRequired,
  };
Chat.defaultProps = {
  searchDebounceMs: 1000,
};
export default withTranslation()(Chat);
