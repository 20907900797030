// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.norgie--norgie--XUgG5 {

}

.norgie--norgie--XUgG5::after {
  content: '›';
  font-size: 15px;
  font-weight: bold;
  padding-left: 8px;
  margin-right: 16px;
  transform: rotate(0deg);

  transition-duration: 0.25s;
  transition-property: rotate, transform;
}

.norgie--opened--Hn1zM::after {
  transform: rotate(90deg) translate(-20%, -15%);
}

@media print {
  .norgie--norgie--XUgG5::after {
    content: '';
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/settings/common/norgie.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;EAClB,uBAAuB;;EAEvB,0BAA0B;EAC1B,sCAAsC;AACxC;;AAEA;EACE,8CAA8C;AAChD;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".norgie {\n\n}\n\n.norgie::after {\n  content: '›';\n  font-size: 15px;\n  font-weight: bold;\n  padding-left: 8px;\n  margin-right: 16px;\n  transform: rotate(0deg);\n\n  transition-duration: 0.25s;\n  transition-property: rotate, transform;\n}\n\n.opened::after {\n  transform: rotate(90deg) translate(-20%, -15%);\n}\n\n@media print {\n  .norgie::after {\n    content: '';\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"norgie": `norgie--norgie--XUgG5`,
	"opened": `norgie--opened--Hn1zM`
};
export default ___CSS_LOADER_EXPORT___;
