import React, { useCallback,useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Trans, withTranslation } from 'react-i18next';
import forEach from 'lodash/forEach';
import { fieldsAreValid } from '../../core/forms';
import debounce from 'lodash/debounce';
import without from 'lodash/without';
import moment from 'moment';
import get from 'lodash/get'
import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import values from 'lodash/values';
import { Box, Flex, Text } from 'theme-ui';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import EditIcon from '@material-ui/icons/EditRounded';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { appointementSchema as validationSchema } from '../../core/clinicUtils';
import {utils as vizUtils } from '@diabee/viz';
const { getLocalizedCeiling } = vizUtils.datetime;
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import {
  MediumTitle,
  Body1,
} from '../../components/elements/FontStyles';
import Button from '../../components/elements/Button';
import Icon from '../../components/elements/Icon';
import Table from '../../components/elements/Table';
import Pagination from '../../components/elements/Pagination';
import TextInput from '../../components/elements/TextInput';
import BgRangeSummary from '../../components/clinic/BgRangeSummary';
import Pill from '../../components/elements/Pill';
import PopoverMenu from '../../components/elements/PopoverMenu';
import PopoverLabel from '../../components/elements/PopoverLabel';
import Popover from '../../components/elements/Popover';
import RadioGroup from '../../components/elements/RadioGroup';
import Checkbox from '../../components/elements/Checkbox';
import FilterIcon from '../../core/icons/FilterIcon.svg';
import SendEmailIcon from '../../core/icons/SendEmailIcon.svg';
import AppointementForm from '../../components/clinic/AppointementForm';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '../../components/elements/Dialog';

import { useToasts } from '../../providers/ToastProvider';
import * as actions from '../../redux/actions';
import { useIsFirstRender , useLocalStorage } from '../../core/hooks';
import { borders, colors } from '../../themes/baseTheme';

export const Diarry = (props) => {
  const { t, i18n, api, trackMetric, searchDebounceMs } = props;
  const isFirstRender = useIsFirstRender();
  const dispatch = useDispatch();
  const { set: setToast } = useToasts();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showAddAppointementDialog, setShowAddAppointementDialog] = useState(false);
  const [showUpdateAppointementDialog,setShowUpdateAppointementDialog] = useState(false);
  const [selectedAppointement, setSelectedAppointement] = useState(null);
  const [isHonoredDialog, setIsHonoredDialog] = useState(null);
  const [cancelDialog, setCancelDialog] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = React.useState('');
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const allUsersMap = useSelector((state) => state.blip.allUsersMap);
  const selectedClinicId = useSelector((state) => state.blip.selectedClinicId);
  const loggedInUserId = useSelector((state) => state.blip.loggedInUserId);
  const rowsPerPage = 8;
  const clinics = useSelector((state) => state.blip.clinics);
  const clinic = get(clinics, selectedClinicId);
  const [appointementFormContext, setAppointementFormContext] = useState();
  const [appointementFetchOptions, setAppointementFetchOptions] = useState({});
  const [appointementFetchCount, setAppointementFetchCount] = useState(0);
  const {
    fetchingAppointementsClinic,
	fetchingPatientsForClinic,
	creatingAppointement,
	updatingAppointement,
  } = useSelector((state) => state.blip.working);
  const defaultAppointementFilterState = {
    date: null,
  };
  const dateAppointementFilterOptions = [
    { value: 1, label: t('Today') },
    { value: 2, label: t('Today and Tomorrow') },
    { value: 7, label: t('On the next 7 days') },
	{ value: 14, label: t('On the next 14 days') },
    { value: 30, label: t('On the next 30 days') },
  ];
  const defaultAppointementFetchOptions = {
    search: '',
    offset: 0,
    sort: '+fullName',
  };
  const [datePeriod, setDatePeriod] = useState(1);
  const [appointementPendingFilters,setAppointementPendingFilters] = useState(datePeriod);
  const datePopupFilterState = usePopupState({
    variant: 'popover',
    popupId: 'dateFilters',
  });
  
  const debounceSearch = useCallback(debounce(search => {
    setAppointementFetchOptions({
      ...appointementFetchOptions,
      offset: 0,
      sort: '+fullName',
      search,
    });
  }, searchDebounceMs), [appointementFetchOptions]);
  const [activeAppointementFilters, setActiveAppointementFilters] = useLocalStorage('activeAppointementFilters', defaultAppointementFilterState);
  const [pendingAppointementFilters, setPendingAppointementFilters] = useState(activeAppointementFilters)
  function handleAsyncResult(workingState, successMessage) {
    const { inProgress, completed, notification } = workingState;

    if (!isFirstRender && !inProgress) {
      if (completed) {
        setCancelDialog(false);

        setToast({
          message: successMessage,
          variant: 'success',
        });

        setSelectedAppointement(null);
      }

      if (completed === false) {
        setToast({
          message: get(notification, 'message'),
          variant: 'danger',
        });
      }
    }
  }
  useEffect(() => {
    setLoading(fetchingAppointementsClinic.inProgress);
  }, [fetchingAppointementsClinic.inProgress]);
  useEffect(() => {
    handleAsyncResult(updatingAppointement, t('You have successfully updated an appointement.'));
  }, [updatingAppointement]);
  useEffect(() => {
    handleAsyncResult(creatingAppointement, t('You have successfully added a new appointement.'));
  }, [creatingAppointement]);
  // Fetchers
  useEffect(() => {
    if (
      loggedInUserId
      && clinic?.id
      && !fetchingAppointementsClinic.inProgress
    ) {
      dispatch(actions.async.fetchAppointementsClinic(api, clinic.id));
    }
  }, [loggedInUserId]);

 

  useEffect(() => {
	  if(clinic?.appointementCount)
		setPageCount(Math.ceil(clinic.appointementCount / rowsPerPage));
  }, [clinic]);


  useEffect(() => {
    const { inProgress, completed, notification } = fetchingAppointementsClinic;
	
    if (!isFirstRender && !inProgress) {
      if (completed === false) {
        setToast({
          message: get(notification, 'message'),
          variant: 'danger',
        });
      }
    }
  }, [fetchingAppointementsClinic]);
  useEffect(() => {
    const fetchOptions = {
      offset: 0,
      sort: appointementFetchOptions.sort || defaultAppointementFetchOptions.sort,
      limit: 50,
      search: appointementFetchOptions.search,
    };

    if (isEmpty(fetchOptions.search)) delete fetchOptions.search;

    if (activeAppointementFilters.date) {
      fetchOptions['toDate'] = moment().add(activeAppointementFilters.date, 'days').toISOString();
    }

    setAppointementFetchOptions(fetchOptions);
  }, [activeAppointementFilters, clinic?.id, datePeriod]);
  
  useEffect(() => {
    if (
      loggedInUserId
      && clinic?.id
      && !fetchingPatientsForClinic.inProgress
      && !isEmpty(appointementFetchOptions)
    ) {
      const fetchOptions = { ...appointementFetchOptions };
      if (isEmpty(fetchOptions.search)) delete fetchOptions.search;
      dispatch(actions.async.fetchAppointementsClinic(api, clinic.id, fetchOptions));
    }
  }, [loggedInUserId, appointementFetchOptions]);
  function handleRefreshPatients() {
    trackMetric(prefixPopHealthMetric('Refresh data'), { clinicId: selectedClinicId });
    let fetchOptions = { ...appointementFetchOptions };
    if (isEmpty(fetchOptions.search)) delete fetchOptions.search;
    dispatch(actions.async.fetchPatientsForClinic(api, clinic.id, fetchOptions));
  }
  function handleHonored(appointement){
	  setIsHonoredDialog(true);
  }
  
  function handleCancel(){
	  setCancelDialog(true);
  }
  

  function handleConfirmHonored(appointement) {
    //trackMetric('Clinic - Accept patient invite', { clinicId: selectedClinicId });
    dispatch(actions.async.honoredAppointement(api, appointement.userId, appointement));
	setIsHonoredDialog(false);
	//handleRefetchAppointements();
  }

  function handleUpdate(member) {
    //trackMetric('Clinic - Decline patient invite', { clinicId: selectedClinicId });
    setSelectedAppointement({...member});
    setShowUpdateAppointementDialog(true);
  }

  function handleConfirmDecline(invite) {
    trackMetric('Clinic - Decline patient invite confirmed', { clinicId: selectedClinicId });
    dispatch(actions.async.deletePatientInvitation(api, clinic.id, invite.key));
  }


const renderActions = member => (
    <Flex justifyContent="flex-end">
      <Button
        className="update-appointement"
        onClick={handleUpdate(member)}
        processing={updatingAppointement.inProgress && member.id === selectedAppointement.id}
        variant="secondary"
      >
        {t('Change Appointement')}
      </Button>

      <Button
        className="honnored-appointement"
        onClick={() => {
          setSelectedAppointement(member);
          handleHonored(member);
        }}
        processing={updatingAppointement.inProgress && member.id === selectedAppointement.id}
        variant="secondary"
        color="green"
        bg="white"
        ml={2}
      >
        {t('Honored')}
      </Button>
	  <Button
        className="cancel-appointement"
        onClick={() => {
          setSelectedAppointement(member);
          handleCancel();
        }}
        processing={updatingAppointement.inProgress && member.id === selectedAppointement.id}
        variant="primary"
        color="red"
        bg="white"
        ml={2}
      >
        {t('Cancel')}
      </Button>
    </Flex>
  );

const renderAddAppointementDialog = () => {
    return (
      <Dialog
        id="addAppointement"
        aria-labelledby="dialog-title"
        open={showAddAppointementDialog}
        onClose={handleCloseOverlays}
      >
        <DialogTitle onClose={handleCloseOverlays}>
          <MediumTitle id="dialog-title">{t('Add New Appointement')}</MediumTitle>
        </DialogTitle>

        <DialogContent>
          <AppointementForm api={api} onFormChange={handleAppointementForm} patients={clinic?.patients}  />
        </DialogContent>

        <DialogActions>
          <Button id="addAppointementCancel" variant="secondary" onClick={handleCloseOverlays}>
            {t('Cancel')}
          </Button>
          <Button
            id="addAppointementConfirm"
            variant="primary"
            onClick={handleAddAppointementOK}
            processing={creatingAppointement.inProgress}
            disabled={appointementFormContext ? !appointementFormContext.isValid : true}
          >
            {t('Add Appointement')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  const renderUpdateAppointementDialog = () => {
    return (
      <Dialog
        id="updateAppointement"
        aria-labelledby="dialog-title"
        open={showUpdateAppointementDialog}
        onClose={handleCloseOverlays}
      >
        <DialogTitle onClose={handleCloseOverlays}>
          <MediumTitle id="dialog-title">{t('Update Appointement')}</MediumTitle>
        </DialogTitle>

        <DialogContent>
          <AppointementForm t={t} api={api} onFormChange={handleAppointementForm} patients={clinic?.patients} appointement={selectedAppointement}  />
        </DialogContent>

        <DialogActions>
          <Button id="udpateAppointementCancel" variant="secondary" onClick={handleCloseOverlays}>
            {t('Cancel')}
          </Button>
          <Button
            id="updateAppointementConfirm"
            variant="primary"
            onClick={handleUdpateAppointementOK}
            processing={updatingAppointement.inProgress}
            //disabled={!fieldsAreValid(keys(patientFormContext?.values), validationSchema, patientFormContext?.values)}
          >
            {t('Udpate Appointement')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  function handleUdpateAppointementOK(){
	  var selectedDate = moment(appointementFormContext.values.date + " " + appointementFormContext.values.hour,"YYYY-MM-DD HH:mm").toISOString();
	  setSelectedAppointement({id: selectedAppointement.id, clinicianId: clinic.id, time: selectedDate, userId : appointementFormContext.values.userId});
	dispatch(actions.async.updateAppointement(api,appointementFormContext.values.userId, selectedAppointement.id, selectedDate, clinic.id, selectedAppointement));
	setShowUpdateAppointementDialog(false);
	//handleRefetchAppointements();
  };
  function handleConfirmCancel(){
	  if(selectedAppointement)
		dispatch(actions.async.deleteAppointement(api, selectedAppointement?.userId, selectedAppointement));
	  setCancelDialog(false);
	  //handleRefetchAppointements();
  }
  
  function handleAppointementForm(formikContext){
	  setAppointementFormContext({...formikContext});
	  
  }
  function handleAddAppointementOK(){
	  var selectedDate = moment(appointementFormContext.values.date + " " + appointementFormContext.values.hour,"YYYY-MM-DD HH:mm").toISOString();
      dispatch(actions.async.createAppointement(api,clinic,appointementFormContext.values.userId, appointementFormContext.values.name, allUsersMap[loggedInUserId].profile?.clinic?.role, allUsersMap[loggedInUserId].profile?.fullName, selectedDate));
	  setShowAddAppointementDialog(false);
	  handleRefetchAppointements();
  }
  function handleAddAppointement(event){
	  setShowAddAppointementDialog(true);
  }
  function handleUdpateAppointement(event){
	  setShowUpdateAppointementDialog(true);
  }

  function handleClickPatient(appointement) {
    return () => {
      trackMetric('Selected PwD');
      dispatch(push(`/patients/${appointement.userId}/data`));
    }
  }

  function handleRefetchAppointements() {
    trackMetric('Clinic - Refresh patient invites', { clinicId: selectedClinicId });
    dispatch(actions.async.fetchAppointementsClinic(api, clinic?.id));
  }

  function handleSearchChange(event) {
    setPage(1);
    setSearchText(event.target.value);
    if (isEmpty(event.target.value && clinic?.appointementCount)) {
      setPageCount(Math.ceil(clinic.appointementCount / rowsPerPage));
    }
  }

  function handleClearSearch(event) {
    setPage(1);
    setSearchText('');
	if(clinic?.appointementCount)
		setPageCount(Math.ceil(clinic.appointementCount / rowsPerPage));
	else
		setPageCount(1);
  }
  
 function handleCloseOverlays() {
    setShowAddAppointementDialog(false);
	setShowUpdateAppointementDialog(false);
	setShowDeleteDialog(false);
	setIsHonoredDialog(false);
	setCancelDialog(false);
    setTimeout(() => {
      setSelectedAppointement(null);
    })
  }
  const handlePageChange = (event, newValue) => {
    setPage(newValue);
  };

  const handleTableAppointementFilter = (data) => {
    setPageCount(Math.ceil(data.length / rowsPerPage));
  };
  function handleResetAppointementFilters() {
    //trackMetric(prefixPopHealthMetric('Clear all filters'), { clinicId: selectedClinicId });
    setActiveAppointementFilters(defaultAppointementFilterState);
    setPendingAppointementFilters(defaultAppointementFilterState);
  }
  const renderHeader = () => {
    const activeAppointementFiltersCount = without([activeAppointementFilters.date], null, 0).length;
    return (
      <>

        <Flex mb={4} alignItems="center" justifyContent="space-between">
          <Flex
            alignItems="center"
            justifyContent="space-between"
            flexGrow={1}
            pt={0}
          >
            <Flex
              alignItems="center"
              justifyContent="flex-start"
              sx={{ gap: 2 }}
            >
              <Button
                id="add-appointement"
                variant="primary"
                onClick={handleAddAppointement}
                fontSize={0}
                mr={1}
              >
                {t('Add New Appointement')}
              </Button>
			<>
                  <Flex
                    alignItems="center"
                    color={activeAppointementFiltersCount > 0 ? 'purpleMedium' : 'grays.4'}
                    pl={2}
                    py={1}
                    sx={{ gap: 1, borderLeft: borders.divider }}
                  >
                    {activeAppointementFiltersCount > 0 ? (
                      <Pill
                        id="filter-count"
                        label="filter count"
                        round
                        width="14px"
                        lineHeight="15px"
                        fontSize="9px"
                        colorPalette={['purpleMedium', 'white']}
                        text={`${activeAppointementFiltersCount}`}
                      />
                    ) : (
                      <Icon
                        id="filter-icon"
                        variant="static"
                        iconSrc={FilterIcon}
                        label={t('Filter')}
                        fontSize={1}
                        width="14px"
                        color={'grays.4'}
                      />
                    )}
                    <Text fontSize={0}>{t('Filter By')}</Text>
                  </Flex>

                  <Box>
                    <Button
                      variant="filter"
                      id="date-filter-trigger"
                      selected={!!activeAppointementFilters.date}
                      {...bindTrigger(datePopupFilterState)}
                      icon={KeyboardArrowDownRoundedIcon}
                      iconLabel="Filter by appointement date"
                      ml={2}
                      fontSize={0}
                      lineHeight={1.3}
                    >
                      {activeAppointementFilters.date ? find(dateAppointementFilterOptions, { value: activeAppointementFilters.date })?.label : t('Next Appointements')}
                    </Button>
                  </Box>

                  <Popover
                    minWidth="11em"
                    closeIcon
                    {...bindPopover(datePopupFilterState)}
                    
                  >
                    <DialogContent px={2} py={3} dividers>
                      <RadioGroup
                        id="date-filters"
                        name="date-filters"
                        options={dateAppointementFilterOptions}
                        variant="vertical"
                        fontSize={0}
                        value={pendingAppointementFilters.date || activeAppointementFilters.date}
                        onChange={event => {
                          setPendingAppointementFilters({ ...pendingAppointementFilters, date: parseInt(event.target.value) || null });
                        }}
                      />
                    </DialogContent>

                    <DialogActions justifyContent="space-between" p={1}>
                      <Button
                        id="clear-last-upload-filter"
                        fontSize={1}
                        variant="textSecondary"
                        onClick={() => {
                          setPendingAppointementFilters({ ...activeAppointementFilters, date: defaultAppointementFilterState.date });
                          datePopupFilterState.close();
                        }}
                      >
                        {t('Clear')}
                      </Button>

                      <Button id="apply-last-upload-filter" disabled={!pendingAppointementFilters.date} fontSize={1} variant="textPrimary" onClick={() => {
                        const dateRange = pendingAppointementFilters.date === 1
                          ? 'today'
                          : `${pendingAppointementFilters.date} days`;
						setActiveAppointementFilters(pendingAppointementFilters);
                      }}>
                        {t('Apply')}
                      </Button>
                    </DialogActions>
                  </Popover>

                  

                  {activeAppointementFiltersCount > 0 && (
                    <Button
                      id="reset-all-active-filters"
                      variant="textSecondary"
                      onClick={handleResetAppointementFilters}
                      fontSize={0}
                      color="grays.4"
                    >
                      {t('Reset Filters')}
                    </Button>
                  )}
                  
                </>
            </Flex>

            <Flex
              alignItems="center"
              justifyContent="flex-end"
            >
              
            </Flex>
          </Flex>
        </Flex>
      </>
    );
  };
const renderDeleteAppointementDialog = () => {
    const fullName = selectedAppointement?.name;
    return (
      <Dialog
        id="deleteAppointement"
        aria-labelledby="dialog-title"
        open={cancelDialog}
        onClose={handleCloseOverlays}
      >
        <DialogTitle onClose={handleCloseOverlays}>
          <MediumTitle id="dialog-title">{t('Cancel {{name}} appointement', { name: fullName })}</MediumTitle>
        </DialogTitle>

        <DialogContent>
          <Trans className="ModalOverlay-content" i18nKey="html.peopletable-remove-patient-confirm" i18n={i18n} t={t}>
            <Body1>
              Are you sure you want to cancel patient: {{fullName}} appointement?
            </Body1>
          </Trans>
        </DialogContent>

        <DialogActions>
          <Button id="appointementRemoveCancel" variant="secondary" onClick={handleCloseOverlays}>
            {t('No')}
          </Button>
          <Button
            id="appointementRemoveConfirm"
            variant="danger"
            onClick={handleConfirmCancel}
          >
            {t('Yes')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

const renderHonoredAppointementDialog = () => {
    const fullName = selectedAppointement?.name;

    return (
      <Dialog
        id="honoredAppointement"
        aria-labelledby="dialog-title"
        open={isHonoredDialog}
        onClose={handleCloseOverlays}
      >
        <DialogTitle onClose={handleCloseOverlays}>
          <MediumTitle id="dialog-title">{t('Patient {{name}} appointement Honored?', { name: fullName })}</MediumTitle>
        </DialogTitle>

        <DialogContent>
          <Trans className="ModalOverlay-content" i18nKey="html.peopletable-remove-patient-confirm">
            <Body1>
              Are you sure patient {{fullName}} appointement is honored?
            </Body1>
          </Trans>
        </DialogContent>

        <DialogActions>
          <Button id="appointementRemoveCancel" variant="secondary" onClick={handleCloseOverlays}>
            {t('No')}
          </Button>
          <Button
            id="appointementHonoredConfirm"
            variant="danger"
            onClick={handleConfirmHonored()}
          >
            {t('Yes')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };


  const renderName = appointement => (
    <Box className="appointement-patient-name" onClick={handleClickPatient(appointement)}>
      <Text>{appointement.name}</Text>
    </Box>
  );

  const renderDate = ({ time }) => (
    <Box className="appointement-date">
      <Text>{moment(time).format(t('MM/DD/YYYY h:mm a'))}</Text>
    </Box>
  );

  /*const renderActions = member => (
    <Flex justifyContent="flex-end">
      <Button
        className="decline-invite"
        onClick={() => handleDecline(member)}
        processing={deletingPatientInvitation.inProgress && member.key === selectedInvitation.key}
        variant="secondary"
      >
        {t('Decline')}
      </Button>

      <Button
        className="accept-invite"
        onClick={() => {
          setSelectedInvitation(member);
          handleAccept(member);
        }}
        processing={acceptingPatientInvitation.inProgress && member.key === selectedInvitation.key}
        variant="primary"
        color="purpleMedium"
        bg="white"
        ml={2}
      >
        {t('Accept')}
      </Button>
    </Flex>
  );*/

  const columns = [
    {
      title: t('Name'),
      field: 'name',
      align: 'left',
      sortable: true,
      sortBy: 'name',
      searchable: true,
      render: renderName,
    },
    {
      title: t('Appointement Date'),
      field: 'time',
      align: 'left',
      sortable: true,
      sortBy: 'time',
	  searchable: true,
      render: renderDate,
    },
	{
      title: '',
      field: 'actions',
      render: renderActions,
      align: 'left',
    },
  ];

  return (
    <>
      <Box sx={{ position: 'absolute', top: '8px', right: 4 }}>
        <TextInput
          themeProps={{
            width: 'auto',
            minWidth: '250px',
          }}
          fontSize="12px"
          placeholder={t('Search by Name')}
          icon={searchText ? CloseRoundedIcon : SearchIcon}
          iconLabel="search"
          onClickIcon={searchText ? handleClearSearch : null}
          name="search-invites"
          onChange={handleSearchChange}
          value={searchText}
          variant="condensed"
        />
      </Box>
      {renderHeader()}
	  {renderAddAppointementDialog()}
	  {renderUpdateAppointementDialog()}
	  {renderDeleteAppointementDialog()}
      <Box sx={{ position: 'relative' }}>
        <Table
          id="appointementsTable"
          label={t('Clinician Table')}
          columns={columns}
          data={values(clinic?.appointements)}
          orderBy="time"
          order="asc"
          rowHover={false}
          rowsPerPage={rowsPerPage}
          searchText={searchText}
          page={page}
          onFilter={handleTableAppointementFilter}
          emptyText={null}
          fontSize={1}
        />

        {clinic?.appointementCount && clinic.appointementCount > rowsPerPage && (
          <Pagination
            px="5%"
            sx={{ position: 'absolute', bottom: '-50px' }}
            width="100%"
            id="clinic-invites-pagination"
            count={pageCount}
            page={page}
            disabled={pageCount < 2}
            onChange={handlePageChange}
            showFirstButton={false}
            showLastButton={false}
          />
        )}

        {clinic?.appointementCount && clinic.appointementCount === 0 && (
          <Box id="no-invites" pt={3} mb={4} sx={{ borderTop: borders.divider }}>
            <Text p={3} fontSize={1} color="text.primary" textAlign="center">
              {t('There are no appointements. Refresh to check for new appointements.')}
            </Text>

            <Flex justifyContent="center">
              <Button
                id="refresh-invites"
                variant="secondary"
                icon={RefreshRoundedIcon}
                iconPosition="left"
                processing={fetchingAppointementsClinic.inProgress}
                onClick={handleRefetchAppointements}
                sx={{
                  '&:hover,&:active,&.active,&.processing': {
                    color: colors.purpleMedium,
                    backgroundColor: colors.white,
                    borderColor: colors.purpleMedium,
                  },
                }}
              >
                {t('Refresh')}
              </Button>
            </Flex>
          </Box>
        )}
		{!clinic?.appointementCount  && (
          <Box id="no-invites" pt={3} mb={4} sx={{ borderTop: borders.divider }}>
            <Text p={3} fontSize={1} color="text.primary" textAlign="center">
              {t('There are no appointements. Refresh to check for new appointements.')}
            </Text>

            <Flex justifyContent="center">
              <Button
                id="refresh-invites"
                variant="secondary"
                icon={RefreshRoundedIcon}
                iconPosition="left"
                processing={fetchingAppointementsClinic.inProgress}
                onClick={handleRefetchAppointements}
                sx={{
                  '&:hover,&:active,&.active,&.processing': {
                    color: colors.purpleMedium,
                    backgroundColor: colors.white,
                    borderColor: colors.purpleMedium,
                  },
                }}
              >
                {t('Refresh')}
              </Button>
            </Flex>
          </Box>
        )}

      </Box>
    </>
  );
};

Diarry.propTypes = {
  api: PropTypes.object.isRequired,
  trackMetric: PropTypes.func.isRequired,
};

export default withTranslation()(Diarry);
